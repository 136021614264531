const goofy = [
  "ROGER GOODELL",
  "HULK HOGAN",
  "LIL YACHTY",
  "JOHN MADDEN",
  "PAULY SHORE",
  "KATY PERRY",
  "OWEN WILSON",
  "JEAN-CLAUDE VAN DAMME",
  "DEREK JETER",
  "MATTHEW MCCONAUGHEY",
  "EDDIE VAN HALEN",
  "RICHARD SIMMONS",
  "COOLIO",
  'METALLICA: "RIDE THE LIGHTNING"',
  "ROB GRONKOWSKI",
  "PETE WENTZ",
  'TOTO: "AFRICA"',
  "RIFF RAFF",
  "KEVIN FEDERLINE",
  'WILL FERRELL IN "ELF"',
  'ASHTON KUTCHER IN "THE BUTTERFLY EFFECT"',
  'RANDY "MACHO MAN" SAVAGE',
  'DWAYNE "THE ROCK" JOHNSON',
  'NICK CAGE IN "CON AIR"',
  "TGI FRIDAYS EMPLOYEE HANDBOOK, PAGE 14",
  "JUSTIN BIEBER",
  'ADAM SANDLER IN "BILLY MADISON"',
  "GUCCI MANE",
  'ARNOLD SCHWARZENEGGER IN "JINGLE ALL THE WAY"',
  "JUDGE DREDD",
  'TIM "THE TOOL MAN" TAYLOR',
  "JADEN SMITH",
];

const serious = [
  "NOAM CHOMSKY",
  "SIGMUND FREUD",
  "MICHEL FOUCAULT",
  "ROBERT FROST",
  "MAYA ANGELOU",
  "LANGSTON HUGHES",
  "EMILY DICKINSON",
  "NELSON MANDELA",
  "MAHATMA GANDHI",
  "JANE AUSTEN",
  "ERNEST HEMINGWAY",
  "ALBERT CAMUS",
  "ELEANOR ROOSEVELT",
  "HENRY DAVID THOREAU",
  "WILLIAM SHAKESPEARE",
  "KURT VONNEGUT",
  "BERTRAND RUSSEL",
  "WINSTON CHURCHILL",
  "QUEEN ELIZABETH",
];

const authors = { goofy, serious };

export default authors;
