const goofy = [
  "If a man does not have the sauce, then he is lost. But the same man can be lost in the sauce.",
  "I'm not ranting and raving. I'm just telling you. You know, you're dishonest people. But I'm not ranting and raving.",
  "My name is whatever anyone wants it to be. As a matter of fact, I might change my name to Captain Funzo, then what are they going to say?",
  "I could've played for the Vancouver Super Models but they found codeine in my water bottles.",
  "Because comparatively speaking to me, you are nothing but a grain of sand in the Sahara Desert; and I am the whole desert!",
  "Unbelievable. Time Distortions. Space is the place, Mean Gene Okerlund.",
  "I actually don't like thinking. I think people think I like to think a lot. And I don't. I don’t like to think.",
  "Drugs are bad I'm acknowledging that but when I'm on the drugs I don't have a problem wit that.",
  "A day without sunshine is like, you know, night.",
  "My biggest dream was so big - I couldn't remember it, it was so big.",
  "Rain on my head... Call that brainstorming.",
  "You would have to eat 5 apples today to get the same nutritional value as an apple from 1950.",
  "When they can't do what you do, they get real mad, you ever had salt and vinegar chips?",
  "What if there's people saying something, something in different languages but you can't even understand it?",
  "There's a gummy bear in my throat. It's large so you might have difficulties hearing me.",
  "My greatest pain in life is that I will never be able to see myself perform live.",
  "Hey, ever had a big problem taking care of your eggs and keeping them from getting squashed or spoiled? Keep em outside, except if it's hot out, then you are out of luck pal.",
  "If you're raking the leaves and it gets all over your driveway, just hose it off dummy.",
  "I don't like sand. It's coarse and rough and irritating and it gets everywhere.",
  "Don't try it, Anakin. I have the high ground!",
  "Did you ever hear the tragedy of Darth Plagueis The Wise?. I thought not. It's not a story the Jedi would tell you.",
  "Now THIS is pod racing!",
];

const serious = [
  "All that we are is the result of what we have thought. The mind is everything. What we think we become.",
  "If one advances confidently in the direction of his dream, and endeavours to live the life which he had imagines, he will meet with a success unexpected in common hours.",
  "To live is the rarest thing in the world. Most people exist, that is all.",
  "Darkness cannot drive out darkness: only light can do that. Hate cannot drive out hate: only love can do that",
  "To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.",
  "The person, be it gentleman or lady, who has not pleasure in a good novel, must be intolerably stupid.",
  "The opposite of love is not hate, it's indifference. The opposite of art is not ugliness, it's indifference. The opposite of faith is not heresy, it's indifference. And the opposite of life is not death, it's indifference.",
  "I am enough of an artist to draw freely upon my imagination. Imagination is more important than knowledge. Knowledge is limited. Imagination encircles the world.",
  "There is no greater agony than bearing an untold story inside you.",
  "Love looks not with the eyes, but with the mind, and therefore is winged Cupid painted blind.",
  "To thine own self be true, and it must follow, as the night the day, thou canst not then be false to any man.",
  "Life ... is a tale told by an idiot, full of sound and fury, signifying nothing.",
  "Men at some time are the masters of their fates: The fault, dear Brutus, is not in our stars, but in ourselves, that we are underlings.",
];

const quotes = { goofy, serious };

export default quotes;
